import { callWithLoading, stateBase } from '@/helpers/store';
import { StateBase } from '@/types/store';
import { AxiosError } from 'axios';
import { create } from 'zustand';
import { showToast } from '@/stores/app';
import { ToastType } from '@/types/toast';
import productsApi from '@/api/products';
import { Product, Promotion, ClaimDailyGemsResponse, PaymentCheckoutResponse, VerifyPurchaseResponse, GetProductsAndPromotionsResponse } from '@/types/products';
import { UserSubscription } from '@/models/user';
import { useUserStore } from './user';
import { openModal } from '@/components/BaseModal';
import { Modals } from '@/types/modals';

export interface ProductsState extends StateBase {
  products: Product[];
  promotions: Promotion[];
  selectedProductId: number | null;
  selectedCheckoutSession: PaymentCheckoutResponse | null;
  claimDailyGemsLoading: boolean;
  subscriptionPromotionEndTime: number | null;

  hasFreeGems: () => boolean;
  getProductsAndPromotions: () => void;
  getNextPromotion: () => Promotion | null;
  claimDailyGems: () => void;
  checkoutProduct: (product_id: number) => Promise<boolean>;
  verifyPurchase: (session_id: string) => Promise<boolean>;
  cancelSubscription: (notes?: string) => Promise<boolean>;
  clearSelectedProduct: () => void;
  reset: () => void;
}

export const useProductsStore = create<ProductsState>()((set, get) => ({
  ...stateBase,
  products: [],
  promotions: [],
  selectedProductId: null,
  selectedCheckoutSession: null,
  claimDailyGemsLoading: false,
  subscriptionPromotionEndTime: Date.now() + 3600000,
  hasFreeGems: () => {
    const products = get().products;
    return products.some(product => product.usd_cents === 0 && (product.remaining_time == null || product.remaining_time <= 0));
  },
  getNextPromotion: () => {
    const promotions = get().promotions;
    if (!promotions || promotions.length === 0) return null;
    const validPromotions = promotions.filter(p => p && p.remaining_time && p.remaining_time > 0);
    return validPromotions.reduce((closest, promotion) => {
      if (closest === null || (promotion.remaining_time ?? Infinity) < (closest.remaining_time ?? Infinity)) {
        return promotion;
      }
      return closest;
    }, null as Promotion | null);
  },
  getProductsAndPromotions: async () => {
    const res = await callWithLoading<GetProductsAndPromotionsResponse | AxiosError<any, any>>(productsApi.getProductsAndPromotions(), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t get products: ${res.response?.data?.detail || res.message}`, ToastType.ERROR);
    } else {
      // const sortedProducts = res?.products.sort((a, b) => (a.sort_rank?? 0) - (b.sort_order ?? 0) || (a.usd_cents ?? 0) - (b.usd_cents ?? 0));
      set({ products: res?.products, promotions: res?.promotions });
    }
  },
  claimDailyGems: async () => {
    set({ claimDailyGemsLoading: true });
    const res = await callWithLoading<ClaimDailyGemsResponse | AxiosError<any, any>>(productsApi.claimDailyGems(), set);
    if (res instanceof AxiosError) {
      showToast(`Can\'t get daily gems: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
    } else {
      useUserStore.getState().setUserStats({ gem_balance: res?.gem_balance });
      if (res?.amount_granted && res.amount_granted > 0) {
        openModal(Modals.SuccessPayment);
      }
      set(state => {
        const updatedProducts = state.products.map(product => {
          if (product.usd_cents === 0) {
            return { ...product, remaining_time: res?.remaining_time, active: false };
          }
          return product;
        });
        return { ...state, products: updatedProducts };
      });
    }
    set({ claimDailyGemsLoading: false });
  },
  checkoutProduct: async (product_id: number) => {
    console.log('[DEBUG] checkoutProduct', product_id);
    set({ selectedProductId: product_id });
    const res = await callWithLoading<PaymentCheckoutResponse | AxiosError<any, any>>(
      productsApi.checkoutProduct(product_id),
      set,
    );
    if (res instanceof AxiosError) {
      showToast(`Can\'t get purchase info: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      return false;
    } else {
      console.log('[DEBUG] checkoutProduct in useProductsStore', res);
      set({ selectedCheckoutSession: res });
      return true;
    }
  },
  verifyPurchase: async (session_id: string) => {
    const res = await callWithLoading<VerifyPurchaseResponse | AxiosError<any, any>>(
      productsApi.verifyPurchase(session_id),
      set,
    );
    if (res instanceof AxiosError) {
      showToast(`Can\'t get purchase info: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      return false;
    } else {
      useUserStore.getState().setUserStats({ gem_balance: res?.gem_balance });
      return true;
    }
  },
  cancelSubscription: async (notes?: string) => {
    const res = await callWithLoading<UserSubscription | AxiosError<any, any>>(
      productsApi.cancelSubscription(notes),
      set,
    );
    if (res instanceof AxiosError) {
      showToast(`Can\'t cancel subscription: ${res.response?.data.detail || res?.message}`, ToastType.ERROR);
      return false;
    } else {
      useUserStore.getState().setUserStats({ subscription: res });
      return true;
    }
  },
  clearSelectedProduct: () => {
    set({ selectedProductId: null });
  },
  reset: async () => set({ ...stateBase }),
}));
