import { useCallback } from 'react';
import { Modals } from '@/types/modals';
import { BaseModal } from '../BaseModal';
import { loadStripe } from '@stripe/stripe-js/pure';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { useProductsStore } from '@/stores/products';
import { useUserStore } from '@/stores/user';

interface Props {
  onPaymentSucceeded: () => void;
  onPaymentError: (error: string) => void;
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);

export const ProductCheckoutModal: React.FC<Props> = ({ onPaymentSucceeded, onPaymentError }) => {
  const { selectedCheckoutSession, verifyPurchase } = useProductsStore();

  const handleComplete = useCallback(async () => {
    if (selectedCheckoutSession?.session_id) {
      const verified = await verifyPurchase(selectedCheckoutSession.session_id);
      if (verified) {
        onPaymentSucceeded();
      } else {
        onPaymentError('Payment failed or was incomplete.');
      }
      return;
    }
    useProductsStore.setState({ selectedProductId: null });
  }, [selectedCheckoutSession]);

  return (
    <BaseModal className="overflow-auto backdrop-filter backdrop-blur-md bg-black bg-opacity-50" id={Modals.ProductCheckout}>
      <div className="relative w-[92%] sm:w-[480px]">
        <div className={'absolute h-full w-full'} />
        {selectedCheckoutSession?.client_secret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              clientSecret: selectedCheckoutSession.client_secret,
              onComplete: handleComplete,
            }}
            key={selectedCheckoutSession.client_secret} // This ensures re-rendering
          >
            <EmbeddedCheckout className="max-h-[90dvh] relative z-10" />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </BaseModal>
  );
};
