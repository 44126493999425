// Modals should be unique because values will be used as html ids
export enum Modals {
  SignIn = 'sign-in-modal',
  Storage = 'storage-modal',
  SuccessPayment = 'success-payment-modal',
  FailurePayment = 'failure-payment-modal',
  Welcome = 'welcome-modal',
  DeleteChat = 'delete-chat-modal',
  ResetChat = 'reset-chat-modal',
  UnlockMessage = 'unlock-message-modal',
  UnlockMessageReset = 'unlock-message-reset-modal',
  UnlockCharacter = 'unlock-character-modal',
  UnlockCharacterCash = 'unlock-character-cash-modal',
  ImageDetail = 'image-detail',
  ImageBrowser = 'image-browser-modal',
  ProductCheckout = 'product-checkout-modal',
  PreSignIn = 'pre-sign-in-modal',
  NonDismissablePreSignInModal = 'non-dismissable-pre-sign-in-modal',
  Feedback = 'feedback-modal',
  UserInput = 'user-input-modal',
  AudioUnlock = 'audio-unlock-modal',
  FullScreenLoading = 'fullscreen-loading-modal',
  CharacterSelection = 'character-selection-modal',
  UnlockImageGen = 'unlock-image-gen-modal',
  SubscriptionRequired = 'subscription-required-modal',
  UnlockNSFWImages = 'unlock-nsfw-image-modal',
  SuccessSubscription = 'success-subscription-modal',
  CancelSubscription = 'cancel-subscription-modal',
  GenericConfirmation = 'generic-confirmation-modal',
  DeleteAccount = 'delete-account-modal',
}